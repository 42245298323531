import React from 'react';
import { ReactComponent as CrossIcon } from 'images/cross-icon.svg';
import { ReactComponent as CircularCheckIcon } from 'images/circular-check-icon.svg';

import styles from './CardComparisonTable.module.scss';

interface DataComparisonTable {
  labelTd: string;
  debitTd: React.ReactNode;
  creditTd: React.ReactNode;
  planneryTd: React.ReactNode | string;
}

const dataComparisonTable: DataComparisonTable[] = [
  {
    labelTd: 'Rewards',
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: 'Over-spending',
    planneryTd: 'Healthy Spending',
  },
  { labelTd: 'APR', debitTd: <CrossIcon className={styles.grayCrossIcon} />, creditTd: '30.32%', planneryTd: '13.74%' },
  {
    labelTd: 'Avoid Debt',
    debitTd: <CircularCheckIcon />,
    creditTd: <CrossIcon className={styles.grayCrossIcon} />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
  {
    labelTd: 'Build Credit',
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: <CircularCheckIcon />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
  {
    labelTd: 'Guidance',
    debitTd: <CrossIcon className={styles.grayCrossIcon} />,
    creditTd: <CrossIcon className={styles.grayCrossIcon} />,
    planneryTd: <CircularCheckIcon className={styles.purpleCheckIcon} />,
  },
];

const CardComparisonTable = () => (
  <table className={styles.comparisonTable}>
    <thead>
      <tr>
        <th> </th>
        <th className={styles.thWithRadius}>Debit</th>
        <th>Credit</th>
        <th>Plannery</th>
      </tr>
    </thead>
    <tbody>
      {dataComparisonTable.map((row, index) => (
        <tr key={index}>
          <td>{row.labelTd}</td>
          <td>{row.debitTd}</td>
          <td>{row.creditTd}</td>
          <td className={typeof row.planneryTd === 'string' ? styles.highlightText : ''}>{row.planneryTd}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CardComparisonTable;
