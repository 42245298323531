import React from 'react';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import CardComparisonTable from 'components/Card/CardComparisonTable/CardComparisonTable';
import CardBenefitsList, { BenefitItemProps } from 'components/Card/CardBenefitsList/CardBenefitsList';
import { FlowComponentType } from 'routes/types';

import { ReactComponent as TrophyIcon } from 'images/trophy-card-benefit-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-card-benefit-icon.svg';
import { ReactComponent as PercentageIcon } from 'images/percentage-card-benefit-icon.svg';
import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';

import styles from './BuildCreditAvoidDebtSaveMoney.module.scss';

const cardBenefitsList: BenefitItemProps[] = [
  {
    icon: <TrophyIcon />,
    title: 'Rewards for Nurses',
    description: (
      <>
        Earn a projected <span>$500 in rewards</span> over the next year!
      </>
    ),
  },
  {
    icon: <CalendarIcon />,
    title: 'Smart Spending Limits',
    description: (
      <>
        <span>Spend less</span> and get AI coaching to keep you in budget.
      </>
    ),
  },
  {
    icon: <PercentageIcon />,
    title: 'Smart Auto Pay',
    description: (
      <>
        Always pay just enough. <span>Cut interest by up to 75%</span> compared to your Capital One card.
      </>
    ),
  },
  {
    icon: <MoneyIcon />,
    title: 'Import Existing Debt',
    description: (
      <>
        <span>Save up to $1,000</span> when you import your other balances.
      </>
    ),
  },
];

const BuildCreditAvoidDebtSaveMoney = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer
      title="Build Credit, Avoid Debt, and Save Money."
      subtitle={
        <div className={styles.subtitle}>
          Your benefits are worth an estimated
          <span>$2,500 per year.</span>
        </div>
      }
    >
      <CardBenefitsList listBenefits={cardBenefitsList} />

      <div className={styles.comparisonContainer}>
        <div className={styles.comparisonHeader}>
          <span>Compare to Other Cards</span>
          <p>Get the best of credit and debit cards: build credit while avoiding debt.</p>
        </div>

        <CardComparisonTable />
      </div>

      <div className={styles.disclaimerContainer}>
        Displayed terms are expected and <span>subject to change.</span> Rewards are based on an an estimated average
        spending of [$2,000] monthly. Estimated savings is based on expected usage. Credit Card APR is based on [your
        Capital One credit card][the market average for your credit score].
      </div>

      <Button type={ButtonType.Primary} onClick={handleNext}>
        Next
      </Button>
    </FormContainer>
  </>
);

export default BuildCreditAvoidDebtSaveMoney;
