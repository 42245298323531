import CardApplicationSuccessful from 'components/Card/CardApplicationSuccessful';
import ConnectPaycheck, { PaycheckFeedback } from 'components/ConnectPaycheck';
import ReferralProgram from 'components/ReferralProgram';
import { CardNextResult, ConnectPaycheckResult } from 'enums/FlowNextResults';
import { RoutePath } from 'enums/Routes';
import ImportBalance from 'components/CardFlow/ImportBalance';
import BuildCreditAvoidDebtSaveMoney from 'components/CardFlow/BuildCreditAvoidDebtSaveMoney/BuildCreditAvoidDebtSaveMoney';

import { NextFunction, RouterType } from './types';

export const getCardRoutes = (showReferralImmediatelyAfterApply: boolean, next: NextFunction): RouterType => ({
  [RoutePath.ImportBalance]: {
    navigationInfo: { showBackLink: false, title: 'Healthcare Employee Lending' },
    component: ImportBalance,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourSavings);
    },
  },
  [RoutePath.ReferralProgram]: {
    navigationInfo: { showBackLink: false, title: 'Plannery Card' },
    component: ReferralProgram,
    handleNext: (args) => () => {
      next(args)(CardNextResult.FromReferral);
    },
  },
  [RoutePath.CardApplicationSuccessful]: {
    navigationInfo: { showBackLink: false, title: 'Your Offer' },
    component: CardApplicationSuccessful,
    handleNext: (args) => () => {
      const { navigate } = args;
      if (showReferralImmediatelyAfterApply) {
        navigate(RoutePath.BuildCreditAvoidDebtSaveMoney);
      } else {
        next(args)(CardNextResult.FromCardApplied);
      }
    },
  },
  [RoutePath.BuildCreditAvoidDebtSaveMoney]: {
    navigationInfo: { showBackLink: true, title: 'Your Offer' },
    component: BuildCreditAvoidDebtSaveMoney,
    handleNext: (args) => () => {
      next(args)(CardNextResult.FromCardApplied);
    },
  },
  [RoutePath.ConnectPaycheck]: {
    component: ConnectPaycheck,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case ConnectPaycheckResult.Next:
          navigate(RoutePath.ReferralProgram, {
            state: {
              fromPaycheckSwitch: true,
            },
          });
          break;
        case ConnectPaycheckResult.NotNow:
          navigate(RoutePath.ConnectPaycheckFeedback);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.ConnectPaycheckFeedback]: {
    component: PaycheckFeedback,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ReferralProgram);
    },
  },
});
